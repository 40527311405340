<template>
	<div class="pay_body">

		<div class="login_title">
			<!-- 			<a href="javascript:history.go(-1)">
				<div class="zh_title_left"></div>
			</a> -->

			<a href="/indexCenter">
				<img class="home" src="../../../assets/homeIcon.png" width="6%" />
			</a>

			<!-- <a class="contact" href="https://work.weixin.qq.com/kfid/kfcece74e1bff61a3cd">联系客服</a> -->
			缴费
		</div>

		<div class="login_n_box">

			<div class="login_text">
				<span class="login_ts">请选择对应学校班级，检查订书清单</span>
			</div>

			<div class="pay_class">
				{{data.selectLabel}} <span style="margin-left:10px;font-size:.3rem;font-weight: bold;">{{$route.query.res.schoolName?$route.query.res.schoolName:''}}</span>
			</div>

			<div class="pay_title pay_title2">
				缴费内容
			</div>

			<div class="pay_content pay_content2" v-for="(item,index) in data.termList" :key="index">
				<img :src="item.is_checked?imgSrc:imgSrc2" width="6%" style="float: left;margin-top: 0.2rem;"
					@click="selectsFunc(item,index)" />
				<div @click="selectsFunc2(item,index)">
					<span style="font-size:.3rem;margin:0 5px;">▾</span>{{item.termName}}

					<div class="pay_content_r pay_content_r2">

						<div class="pay_content_r_r pay_content_r_r_color">
							￥{{item.price}}
						</div>

					</div>

					<div v-show="item.is_show">
						<div v-for="(item2,index2) in item.contList" :key="index2" class="er_ji">
							{{item2.itemName}}
							<div class="pay_content_r pay_content_r2">

								<div class="pay_content_r_r">
									￥{{item2.price}}
								</div>

							</div>
						</div>
					</div>

				</div>
			</div>

			<div class="pay_content">
				总金额
				<div class="pay_content_r">

					<div class="pay_content_r_r pay_content_r_total">
						￥{{data.totalAmount}}<span>元</span>
					</div>

				</div>
			</div>

			<div class="pay_title pay_title2">
				备注
			</div>

			<el-form class="form_box" :model="ruleForm" :rules="rules" ref="ruleForm">
				<el-form-item prop="remark">
					<el-input type="textarea" class="beizhu" placeholder="请输入备注信息" v-model="ruleForm.remark">
					</el-input>
				</el-form-item>
			</el-form>
		</div>

		<!-- 		<div class="total">
			<span>￥</span>{{data.totalAmount}}
		</div> -->

		<el-button class="login_sub login_sub2" type="danger" @click="submitForm()">确认</el-button>

		<a href="javascript:history.go(-1)">
			<el-button class="login_sub login_sub2 login_sub3" type="danger">返回上一页</el-button>
		</a>

		<a href="https://work.weixin.qq.com/kfid/kfcece74e1bff61a3cd">
			<el-button class="login_sub login_sub2 login_sub4" type="primary">如有疑问，请联系客服</el-button>
		</a>
		
		<div v-if="is_notice==1" class="bj"></div>
		<div class="tx_box" v-if="is_notice==1">
			<div class="tx_title">温馨提示</div>
			<div class="tx_content">
				本次缴纳的是2022年秋季学期的教辅费用。<br/>
				例如：新生缴纳的年级为七年级；<br/>
				七年级缴纳的年级为八年级；以此类推
			</div>
			
			<el-button type="primary" style="width: 40%;margin:10px 0;" @click="hideNotice">确 认</el-button>
			
		</div>
		
	</div>
</template>

<script>
	import {
		url
	} from "../../../assets/js/api.js";
	import qs from 'qs'

	export default {
		components: {

		},
		data() {
			return {
				currentIndex: null,
				imgSrc: require('../../../assets/login_gou1.png'),
				imgSrc2: require('../../../assets/login_gou2.png'),
				ruleForm: {
					remark: "",
				},
				rules: {
					remark: [{
						required: false,
						message: '请填写备注',
						trigger: 'blur'
					}, ],
				},
				defaultIndex: [0, 0],
				active_css: "pay_class_type_btn pay_class_type_btn_active",
				default_css: "pay_class_type_btn pay_class_type_btn_right",
				tdata: [],
				class_list: "请选择年级/班级",
				pickerVisible: false,
				pickData: [],
				result: '',
				data: [], //数据集合
				payItems: [], //商品列表
				paySign: "", //支付码
				totalMoney: 0, //总金额
				i: 0, //计算器
				amount: 0, //前端计算总金额
				jy: 0,
				termIds: "", //学校列表ID,
				is_notice:"",
			};
		},
		created() {
			this.getList();
			this.open();
		},
		filters: {
			orderNumber(res, payRole) {
				if (payRole == 0) {
					return 1;
				} else {
					return res;
				}
			},
		},

		methods: {
			
			hideNotice(){
				this.is_notice="";
			},
			
			//公告
			open() {
				
				if (sessionStorage.getItem('sId') == 113) {
					this.is_notice=1;
				}

			},

			//勾选
			selectsFunc(res, index) {

				if (this.data.termList[index].is_checked) {
					this.data.totalAmount = (this.data.totalAmount - this.data.termList[index].price*this.$route.query.res.orderNum).toFixed(2);
					this.amount = this.data.totalAmount;
				} else {
					this.data.totalAmount = (parseFloat(this.data.totalAmount) + parseFloat(this.data.termList[index]
						.price)*this.$route.query.res.orderNum).toFixed(2);
					this.amount = this.data.totalAmount;
				}

				console.log(this.termIdsArr);
				this.$set(this.data.termList[index], 'is_checked', !(this.data.termList[index].is_checked));
				this.$forceUpdate();

			},

			//展开
			selectsFunc2(res, index) {

				if (this.data.termList[index].is_show) {
					this.data.termList[index].is_show = false;
				} else {
					this.data.termList[index].is_show = true;
				}

				this.$forceUpdate();

			},


			//切换年级数据
			pickerChangeData(res) {
				console.log(res);

				this.pickData = [];
				this.pickData.push(res[0]);
				this.pickData.push(res[0].classList);

			},

			jiaoSe(res) {

				if (res == 1) { //班主任
					this.active_css = "pay_class_type_btn pay_class_type_btn_active";
					this.default_css = "pay_class_type_btn pay_class_type_btn_right";
				} else if (res == 0) { //家长
					this.active_css = "pay_class_type_btn";
					this.default_css = "pay_class_type_btn pay_class_type_btn_right pay_class_type_btn_active";
				}

				this.payRole = res;
			},

			//支付
			submitForm() {
				//备注校色检验
				// if (this.$route.query.res.payRole == "0") {
				// 	this.rules.remark[0].required = true;
				// } else {
				// 	this.rules.remark[0].required = false;
				// }

				this.termIds = "";
				//获取学校ID
				for (let i = 0; i < this.data.termList.length; i++) {
					if (this.data.termList[i].is_checked) {
						this.termIds += this.data.termList[i].termId + ",";
					}
				}

				if (this.termIds.length > 0) {
					this.termIds = this.termIds.slice(0, -1); //即可删除最后一位
				}

				if (this.termIds == "") {
					this.$notify.error({
						title: '提示',
						message: "请选择缴费内容",
						type: 'warn'
					});
					return;
				}

				this.$refs.ruleForm.validate((valid) => {
					if (valid) {

						let params = {
							classId: this.$route.query.res.classId,
							clientNo: this.$route.query.res.openId,
							payRole: this.$route.query.res.payRole,
							remark: this.ruleForm.remark,
							orderNum: this.$route.query.res.orderNum,
							amount: this.amount,
							termIds: this.termIds,
							schoolType: this.$route.query.res.schoolType,
							studentName:this.$route.query.res.studentName,
							schoolName:this.$route.query.res.schoolName,
						};

						this.axios
							.post(url + '/createOrder', params, {})
							.then(res => {
								let data = res.data;
								if (data.code == 200) {
									console.log(data);
									location.href = data.data;
								} else {
									this.$notify.error({
										title: '提示',
										message: data.msg,
										type: 'success'
									});
								}

							})
							.catch(error => console.log(error))
					}
				})
			},

			getList() {

				let params = {
					classId: this.$route.query.res.classId,
					orderNum: this.$route.query.res.orderNum,
				};

				this.axios
					.post(url + '/queryPayCostItem', qs.stringify(params), {})
					.then(res => {
						let data = res.data;
						if (data.code == 200) {
							this.data = data.data;
							this.amount = this.data.totalAmount;

							for (let i = 0; i < this.data.termList.length; i++) {
								this.data.termList[i].is_checked = true;
								this.data.termList[i].is_show = false;
							}

						} else {
							this.$notify.error({
								title: '提示',
								message: data.msg,
								type: 'success'
							});
						}

					})
					.catch(error => console.log(error))
			},
		}
	}
</script>

<style scoped="scoped">
	.bj {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #000;
		opacity: .4;
	}
	.tx_content{line-height: 25px;font-size:.3rem;text-align: left;margin-top:20px;}
	.tx_box{position: absolute;top:10%;left:4%;width: 84%;background: #fff;border-radius: 5px;padding:4%;}
	.tx_title{line-height: 50px;font-size:.35rem;text-align: center;border-bottom:1px solid #ddd;}
	.form_box>>>.el-form-item__content {
		float: left;
		width: 100%;
	}

	.pay_body {
		background: #f5f5f5;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}

	.login_n_box {
		float: left;
		margin-left: 4%;
		width: 92%;
		background: #fff;
		border-radius: 7px;
		margin-top: -50px;
	}

	.login_title {
		background: #ab1e31;
		font-size: .35rem;
		color: #fff;
		height: 100px;
		line-height: 50px;
	}

	.login_text {
		text-align: left;
		margin: 0px 4% 30px 4%;
		color: #999;
	}

	.login_text_big {
		font-size: 22px;
		font-weight: bold;
		color: #333;
	}

	.login_ts {
		float: left;
		font-size: .3rem;
		color: #999;
		margin-top: 10px;
	}

	.pay_class {
		float: left;
		font-size: .3rem;
		margin: 0 4%;
		width: 92%;
		padding: 10px 0 15px 0;
		text-align: left;
		border-bottom: 1px solid #ebebeb;
	}

	.pay_j {
		float: right;
		border-top: 1px solid #999;
		border-right: 1px solid #999;
		height: 10px;
		width: 10px;
		transform: rotate(45deg);
		margin-top: 6px;
	}

	.pay_title {
		float: left;
		margin: 0 4%;
		width: 92%;
		height: 50px;
		line-height: 50px;
		color: #999;
		text-align: left;
		font-size: .3rem;
	}

	.pay_class_type {
		float: left;
		margin: 0 4%;
		width: 92%;
	}

	.pay_class_type div {
		font-size: .3rem;
	}

	.pay_class_type_btn {
		float: left;
		width: 47%;
		height: 40px;
		line-height: 40px;
		color: #999;
		background: #f8f8f8;
		border-radius: 3px;
	}

	.pay_class_type_btn_right {
		float: right;
	}

	.pay_class_type_btn_active {
		background: #ffefe5;
		color: #ab1e31;
	}

	.pay_title2 {
		border-bottom: 1px solid #ebebeb;
	}

	.pay_content {
		float: left;
		margin: 0 4%;
		width: 92%;
		height: auto;
		line-height: 40px;
		text-align: left;
		border-bottom: 1px solid #ebebeb;
		font-size: .28rem;
	}

	.pay_content2 {
		font-size: .28rem;
	}

	.pay_content2 div {
		font-size: .28rem;
	}

	.pay_content2 .pay_content_r {
		font-size: .28rem;
	}

	.pay_content2 .pay_content_r_l {
		font-size: .28rem;
	}

	.pay_content2 .pay_content_r_r {
		font-size: .28rem;
		color: #ab1e31;
	}

	.pay_content2 .pay_content_r_r_color {
		font-size: .28rem;
	}

	.pay_content_r {
		float: right;
		width: 35%;
		font-size: .28rem;
	}

	.pay_content_r {
		width: auto;
	}

	.pay_content_r_l {
		float: left;
		color: #ab1e31;
		font-size: .3rem;
	}

	.pay_content_r_r {
		float: right;
		color: #999;
		font-size: .3rem;
	}

	.pay_content_r_total {
		color: #ab1e31;
		font-size: .3rem !important;
		font-weight: bold;
	}

	.pay_content_r_total span {
		color: #333;
		margin-left: 10px;
		font-size: .25rem !important;
	}

	.beizhu {
		float: left;
		width: 92%;
		margin: 0 4%;
		padding-bottom: 15px;
	}

	.total {
		float: left;
		width: 100%;
		font-size: 25px;
		margin: 15px 0;
	}

	.login_sub {
		float: left;
		margin-left: 4%;
		width: 92%;
		background: #ab1e31;
		border-radius: 5px;
		margin-bottom: 15px;
	}

	.picker>>>li {
		font-size: .35rem;
	}

	.picker>>>.btn {
		font-size: .35rem;
	}

	.login_sub2>>>span {
		font-size: .35rem;
	}

	.zh_title_left {
		width: 10px;
		height: 10px;
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
		position: absolute;
		top: 18px;
		left: 13px;
		transform: rotate(-135deg);
	}

	.contact {
		position: absolute;
		top: 0;
		right: 13px;
		color: #FFFFFF;
		font-size: .3rem;
	}

	.home {
		position: absolute;
		top: 12px;
		left: 13px;
	}

	.er_ji {
		margin-left: .83rem;
		font-size: .25rem;
		color: #999;
	}

	.login_sub3 {
		background: #fff;
		color: #ab1e31;
	}

	.login_sub4 {
		border: none;
		color: #ab1e31;
		background: none;
	}

	.login_sub4>>>span {
		font-size: .25rem;
	}
</style>

<template>
	<div class="body">
		<div class="zh_title">
			<a href="javascript:history.go(-1)">
				<div class="zh_title_left"></div>
			</a>
			我的资料
		</div>

		<div class="ge"></div>

		<div class="container-fluid in_padding">

			<div class="col-xs-12 top_ge"></div>

			<div class="col-xs-12 in_padding pc_list" style="line-height: .8rem;border-top:1px solid #f8f8f8;">
				<div class="pull-left pupc_list_l">
					头像
				</div>

				<div class="pc_list_r" style="margin-top:0.1rem;"></div>
				<img class="pull-right pcd_tx" :src="data.avatar" :onerror="defaultImg" /> &nbsp;

			</div>

			<div class="col-xs-12 in_padding pc_list">
				<div class="pull-left pupc_list_l">
					姓名
				</div>

				<div class="pc_list_r" style="margin-top:0.05rem;"></div>
				<div class="name">{{data.nickName}} &nbsp;</div>

			</div>

			<!-- 			<div class="col-xs-12 in_padding pc_list" style="border-top:1px solid #f8f8f8;">
				<div class="pull-left pupc_list_l">
					性别
				</div>

				<div class="pc_list_r" style="margin-top:0.05rem;"></div>

			</div> -->

			<div @click="callPhone" class="col-xs-12 in_padding pc_list" style="border-top:1px solid #f8f8f8;">
				<div class="pull-left pupc_list_l">
					手机号码
				</div>

				<div class="pc_list_r" style="margin-top:0.05rem;"></div>
				<div class="phone">{{data.phonenumber}}</div>

			</div>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				data: {},
			};
		},
		computed: {
			defaultImg() {
				return 'this.src="' + require('../../../assets/profile.png') + '"';
			}
		},
		methods: {
			callPhone(){
				window.location.href = 'tel://'+this.data.phonenumber;
			},
		},
		created() {
			this.data = JSON.parse(localStorage.getItem('userInfo'));
		}
	}
</script>

<style scoped="scoped">
	.name {
		float: right;
	}

	.body {
		background: #f8f8f8;
		float: left;
		width: 100%;
		height: 100vh;
	}

	.zh_title {
		position: relative;
		height: 50px;
		line-height: 50px;
		font-size: .35rem;
		font-weight: bold;
		text-align: center;
		background: #ab1e31;
		color: #fff;
	}

	.zh_title_left {
		width: 10px;
		height: 10px;
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
		position: absolute;
		top: 18px;
		left: 13px;
		transform: rotate(-135deg);
	}

	.pc_list {
		text-align: left;
		padding: 5%;
		background: #fff;
		margin-top: 1px;
		float: left;
		width: 90%
	}

	.pupc_list_l {
		float: left;
		font-size: .35rem;
	}

	.pc_list_r {
		float: right;
		border-right: 1px solid #999;
		border-bottom: 1px solid #999;
		height: 10px;
		width: 10px;
		transform: rotate(-45deg);
	}

	.ge {
		margin-top: 10px;
		float: left;
		width: 100%;
	}

	.pcd_tx {
		float: right;
		width: 34px;
		height: 34px;
		border-radius: 27px;
		margin-top: -10px;
	}

	.phone {
		float: right;
	}
</style>

<template>
	<div class="body">

		<div class="zh_title">
			<a href="javascript:history.go(-1)">
				<div class="zh_title_left"></div>
			</a>
			缴费内容
		</div>

		<div class="top_box" style="background: #fff;margin-bottom:0;">
			<div class="class_top_box">

				<div class="list">
					<span>学校年级班级名称</span>
					{{data.itemName}}
				</div>

				<div class="list">
					<span>缴费内容</span>
					{{data.termName}}
				</div>

				<div class="list">
					<span>收费类型</span>
					{{data.batchName}}
				</div>

			</div>
		</div>

		<div class="total">
			<div class="col-xs-12 in_padding kq_gly_list">
				{{data.hasPayNumber}}<br>
				<span>已缴人数</span>
			</div>

			<!-- 			<div class="col-xs-12 in_padding kq_gly_list kq_gly_list_red">
				{{orderNumber-hasPayNumber}}<br>
				<span>未缴人数</span>
			</div> -->

			<div class="col-xs-12 in_padding kq_gly_list">
				{{data.totalAmount}}<br>
				<span>缴费总额</span>
			</div>

		</div>

		<!-- <div class="infinite-list" v-infinite-scroll="getOrderDetails" infinite-scroll-throttle-delay="500"
			infinite-scroll-distance="1"> -->
		<div class="infinite-list">
			<div class="class_list_box" v-for="(item,index) in data.orderList" :key="index">
				<div class="class_list">
					<div class="class_list_tx">
						<!-- <img class="class_list_img" :src="item.avatar" :onerror="defaultImg" width="23%"> -->
						<div class="class_list_l">
							<span class="kq_zaotui">{{item.subName}}</span>

						</div>
					</div>

					<div class="class_list_r">
						<span>{{item.amount}}元</span><br />
						{{item.payTime}}
					</div>
				</div>
			</div>
		</div>
		<div v-if="is_show" class="no_more">已加载全部数据</div>
		<!-- </div> -->

	</div>
</template>

<script>
	import {
		url
	} from "../../../assets/js/api.js";
	import qs from 'qs'
	export default {
		data() {
			return {
				data: {},
				pageNum: 1,
				loading: false,
				list: [],
				is_show: false,
				itemName: "",
				payType: "",
				hasPayNumber: "",
				orderNumber: "",
				totalAmount: "",
				res: null,
			};
		},
		created() {
			this.getOrderDetails();
		},
		computed: {
			defaultImg() {
				return 'this.src="' + require('../../../assets/profile.png') + '"';
			}
		},
		methods: {
			getOrderDetails() {
				if (this.loading == false) {
					this.loading = true;
					let token = JSON.parse(localStorage.getItem('userInfo'));

					this.res = JSON.parse(this.$route.query.res);
					console.log(this.res);

					let params = {
						userToken: token.userToken,
						classId: this.res.classId,
						termId: this.res.termId,
						batchType: this.res.batchType,
					};

					this.axios
						.post(url + '/payBillDetail', qs.stringify(params), {})
						.then(res => {
							let data = res.data;
							if (data.code == 200) {
								this.data = data.data;


								// if (this.data.orderList.length > 0) {
								// 	for (let i = 0; i < this.data.orderRecords.length; i++) {
								// 		this.list.push(this.data.orderRecords[i]);
								// 	}
								// }

								this.loading = false;
							} else {
								this.$notify.error({
									title: '提示',
									message: data.msg,
									type: 'success'
								});
								this.loading = false;
								localStorage.removeItem('userInfo');
								location.href = "https://api.zhyjy.net/authorize?returnUrl=http://web.zhyjy.net";
							}

						})
						.catch(error => console.log(error))
				} else {
					this.is_show = true;
				}
			},
		}
	}
</script>

<style scoped="scoped">
	.body {
		float: left;
		width: 100%;
		height: 100vh;
		overflow-y: auto;
		background: #f8f8f8;
	}

	.zh_title {
		position: relative;
		height: 50px;
		line-height: 50px;
		font-size: .35rem;
		font-weight: bold;
		text-align: center;
		background: #ab1e31;
		color: #fff;
	}

	.zh_title_left {
		width: 10px;
		height: 10px;
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
		position: absolute;
		top: 18px;
		left: 13px;
		transform: rotate(-135deg);
	}

	.top_box {
		float: left;
		width: 90%;
		background: #fff;
		margin-bottom: 15px;
		padding: 0 5%;
	}

	.class_top_box {
		float: left;
		width: 100%;
		margin: 15px 0;
	}

	.list {
		float: left;
		width: 100%;
		font-size: .3rem;
		text-align: left;
		line-height: 30px;
	}

	.list span {
		float: left;
		margin-right: 15px;
		color: #999;
		font-size: .3rem;
	}

	.total {
		float: left;
		padding: 0 5%;
		width: 90%;
	}

	.kq_gly_list {
		float: left;
		width: 39%;
		text-align: center;
		margin-top: 15px;
		margin-right: 3.5%;
		background: #fff;
		font-size: .6rem;
		padding: 4.5%;
	}

	.kq_gly_list span {
		font-size: .3rem;
	}

	.kq_gly_list:nth-child(2) {
		margin-right: 0;
	}

	.kq_gly_list_red {
		color: #ab1e31;
	}

	.kq_gly_list_red span {
		color: #333;
	}

	.class_list_box {
		float: left;
		width: 100%;
	}

	.class_list {
		float: left;
		margin: 0 5%;
		width: 84%;
		background: #fff;
		padding: 3%;
		border-radius: 10px;
		margin-top: 3%;
	}

	.class_list_tx {
		float: left;
		width: 63%;
	}

	.class_list_img {
		float: left;
		border-radius: 10px;
	}

	.class_list_l {
		float: left;
		margin-left: 10px;
		font-size: 12px;
		margin-top: 0px;
		text-align: left;
		line-height: 25px;
	}

	.kq_zaotui {
		font-size: 14px;
		text-align: left;
	}

	.class_list_r {
		float: right;
		color: #999;
		font-size: 12px;
		text-align: right;
	}

	.class_list_r span {
		color: #ab1e31;
		font-size: 14px;
		line-height: 30px;
	}

	.infinite-list {
		float: left;
		width: 100%;
		height: 56%;
		overflow-y: auto;
	}

	.no_more {
		float: left;
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-size: .3rem;
	}
</style>

<template>
	<div class="pay_body">

		<div class="login_title">
			<a href="javascript:history.go(-1)">
				<div class="zh_title_left"></div>
			</a>
			查询订单列表
		</div>

		<div class="login_n_box">

			<div class="ol_list" v-for="(item,index) in data" :key="index">

				<router-link :to="'/orderDetails?orderNo='+item.orderNo">
					<div class="ol_list_min_title">
						<div class="ol_list_min_title_l">
							{{item.orderTime}}
						</div>
						<div v-if="item.orderStatus!=1" class="ol_list_min_title_r">
							{{item.orderStatus|orderStatus}}
						</div>

						<div v-if="item.orderStatus==1" class="ol_list_min_title_r2">
							{{item.orderStatus|orderStatus}}
						</div>

					</div>

					<div class="ol_list_name">
						<div class="ol_list_name_l">
							姓名：{{item.remark}}
						</div>
						<div class="ol_list_name_r">
							<div class="zh_title_left zh_title_right"></div>
						</div>
					</div>

				</router-link>

				<div class="ol_list_name">
					<div class="ol_list_name_l">
						交易金额：{{item.totalAmount}}元
					</div>
					<div class="ol_list_name_r">
						<button v-if="item.orderStatus==0" class="pay_btn" @click="paying(item.orderNo)">支付</button>
					</div>
				</div>

			</div>

		</div>

	</div>
</template>

<script>
	import {
		url
	} from "../../../assets/js/api.js";
	import qs from 'qs'

	export default {
		components: {

		},
		data() {
			return {
				data: [], //数据集合
				orderNo: "",
			};
		},
		filters: {
			orderStatus(res) {
				if (res == 0) {
					return "待支付";
				} else if (res == 1) {
					return "缴费成功";
				} else if (res == 2) {
					return "缴费失败";
				} else if (res == 3) {
					return "退款待审核";
				} else if (res == 4) {
					return "已退全款";
				} else if (res == 5) {
					return "部分退款";
				} else if (res == 6) {
					return "退款失败";
				} else if (res == 7) {
					return "缴费已取消";
				}
			},
			// payRole(res) {
			// 	if (res == 0) {
			// 		return "家长";
			// 	} else if (res == 1) {
			// 		return "班主任";
			// 	} else if (res == 2) {
			// 		return "其他";
			// 	}
			// },
		},
		created() {
			this.getList();
		},
		methods: {

			getList() {
				let params = {
					clientNo: this.$route.query.openId,
				};

				this.axios
					.post(url + '/queryOrderList', qs.stringify(params), {})
					.then(res => {
						let data = res.data;
						if (data.code == 200) {
							this.data = data.data;
						} else {
							this.$notify.error({
								title: '提示',
								message: data.msg,
								type: 'success'
							});
						}

					})
					.catch(error => console.log(error))
			},

			paying(orderNo) {
				let params = {
					orderNo: orderNo,
				};

				this.axios
					.post(url + '/toPay', qs.stringify(params), {})
					.then(res => {
						let data = res.data;
						if (data.code == 200) {
							console.log(data.data);
							location.href = data.data;
						} else {
							this.$notify.error({
								title: '提示',
								message: data.msg,
								type: 'success'
							});
						}

					})
					.catch(error => console.log(error))
			},

		}
	}
</script>

<style scoped="scoped">
	.pay_body {
		background: #f5f5f5;
		float: left;
		height: 100%;
		width: 100%;
	}

	.login_n_box {
		float: left;
		margin-left: 4%;
		width: 92%;
		border-radius: 7px;
	}

	.login_title {
		background: #ab1e31;
		font-size: .35rem;
		color: #fff;
		height: 50px;
		line-height: 50px;
	}

	.login_text {
		text-align: left;
		margin: 0px 4% 30px 4%;
		color: #999;
	}

	.login_text_big {
		font-size: 22px;
		font-weight: bold;
		color: #333;
	}

	.login_ts {
		float: left;
		font-size: 15px;
		color: #333;
		margin-top: 10px;
	}

	.login_ts span {
		color: #999;
		font-size: 15px;
	}

	.zh_title_left {
		width: 10px;
		height: 10px;
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
		position: absolute;
		top: 18px;
		left: 13px;
		transform: rotate(-135deg);
	}

	.ol_list {
		float: left;
		width: 92%;
		margin-top: .25rem;
		background: #fff;
		padding: 0 4%;
		border-radius: 3px;
	}

	.ol_list_min_title {
		float: left;
		width: 100%;
		border-bottom: 1px solid #F0F0F0;
		height: .8rem;
		line-height: .8rem;
	}

	.ol_list_min_title_l {
		float: left;
		font-size: .3rem;
	}

	.ol_list_min_title_r {
		float: right;
		font-size: .3rem;
		color: #D81E37;
	}

	.ol_list_min_title_r2 {
		float: right;
		font-size: .3rem;
		color: #008000;
	}

	.ol_list_name {
		float: left;
		width: 100%;
		line-height: .5rem;
	}

	.ol_list_l {
		float: left;
	}

	.ol_list_r {
		float: right;
	}

	.ol_list_name_l {
		float: left;
		font-size: .3rem;
	}

	.ol_list_name_r {
		position: relative;
		float: right;
	}

	.zh_title_right {
		float: right;
		z-index: 1;
		transform: rotate(45deg);
		left: unset;
		right: 13px;
		border-top: 2px solid #999;
		border-right: 2px solid #999;
		width: 8px;
		height: 8px;
	}

	.pay_btn {
		color: #D81E37;
		border: 1px solid #D81E37;
		background: none;
		border-radius: 1rem;
		font-size: .25rem;
		padding: .06rem .3rem;
		margin: .15rem 0;
		outline: none;
	}
</style>

<template>
	<div></div>
</template>

<script>
	export default {
		data() {
			return {

			};
		},
		created() {
			//判断是否微信端 
			let ua = navigator.userAgent.toLowerCase();
			if (ua.match(/MicroMessenger/i) == "micromessenger") {
				//微信端
				// alert(this.$route.query.sId);
				sessionStorage.setItem('sId',this.$route.query.sId);
				sessionStorage.setItem('sType',this.$route.query.sType);
				location.href='https://api.zhyjy.net/authorize?returnUrl=https://web.zhyjy.net/auth_info';
				return true;
			} else {
				sessionStorage.setItem('sId',this.$route.query.sId);
				this.$router.push('/auth_info?openId=zfb');
				return false;
			}
		},
	}
</script>

<style scoped="scoped">
</style>

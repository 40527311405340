<template>
	<div class="set_body">

		<div class="zh_title">
			<a href="javascript:history.go(-1)">
				<div class="zh_title_left"></div>
			</a>
			修改密码
		</div>

		<div class="login_n_box">

			<el-form class="form_box" :model="ruleForm" status-icon :rules="rules" ref="ruleForm">

				<div class="login_n_box2">

					<el-form-item prop="oldPassword" class="pass_border" label="旧密码">
						<el-input class="login_input" placeholder="" show-password v-model="ruleForm.oldPassword">
						</el-input>
					</el-form-item>

					<el-form-item prop="newPassword" class="pass_border" label="新密码">
						<el-input class="login_input" placeholder="" show-password v-model="ruleForm.newPassword">
						</el-input>
					</el-form-item>

					<el-form-item prop="againPass" class="pass_border" label="确认新密码">
						<el-input class="login_input login_input2" placeholder="" show-password
							v-model="ruleForm.againPass">
						</el-input>
					</el-form-item>

				</div>

				<el-form-item>
					<el-button class="login_sub login_sub2" type="danger" @click="submitForm('ruleForm')">确认修改
					</el-button>
				</el-form-item>

			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		url
	} from "../../../assets/js/api.js";
	export default {
		data() {
			return {
				ruleForm: {
					oldPassword: "",
					newPassword: "",
					againPass: "",
				},
				pass: "",
				rules: {
					oldPassword: [{
						required: true,
						message: '请输入旧密码',
						trigger: 'blur'
					}, ],
					newPassword: [{
						required: true,
						message: '请输入新密码',
						trigger: 'blur'
					}, ],
					againPass: [{
						required: true,
						message: '请再次输入确认密码',
						trigger: 'blur'
					}, ],
				}
			};
		},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						
						if(this.ruleForm.newPassword!=this.ruleForm.againPass){
							this.$notify({
								title: '提示',
								message: "新密码与确认密码不一致",
								type: 'warning'
							});
							return;
						}

						let token = JSON.parse(localStorage.getItem('userInfo'));

						let params = {
							userToken: token.userToken,
							oldPassword: this.ruleForm.oldPassword,
							newPassword: this.ruleForm.newPassword,
						};

						this.axios
							.post(url + '/changePassword', params, {})
							.then(res => {
								let data = res.data;
								if (data.code == 200) {

									localStorage.removeItem('userInfo');

									this.$notify({
										title: '提示',
										message: data.msg,
										type: 'success'
									});
									location.href="https://api.zhyjy.net/authorize?returnUrl=http://web.zhyjy.net";
								} else {
									this.$notify.error({
										title: '提示',
										message: data.msg,
										type: 'success'
									});
									localStorage.removeItem('userInfo');
									location.href ="https://api.zhyjy.net/authorize?returnUrl=http://web.zhyjy.net";
								}

							})
							.catch(error => console.log(error))

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		}
	}
</script>

<style scoped="scoped">
	.form_box {
		float: left;
		width: 100%;
	}

	.set_body {
		background: #f8f8f8;
		height: 100vh;
	}

	.zh_title {
		position: relative;
		height: 50px;
		line-height: 50px;
		font-size: .35rem;
		font-weight: bold;
		text-align: center;
		background: #ab1e31;
		color: #fff;
	}

	.zh_title_left {
		width: 10px;
		height: 10px;
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
		position: absolute;
		top: 18px;
		left: 13px;
		transform: rotate(-135deg);
	}

	.login_n_box {
		float: left;
		width: 100%;
	}

	.login_n_box2 {
		background: #fff;
		width: 90%;
		padding: 0 5%;
		margin-top: 10px;
	}

	.pass_title {
		float: left;
		width: 100%;
		color: #999;
		margin-top: 20px;
		margin-bottom: 15px;
		text-align: left;
	}

	.login_input {
		float: left;
		border: none;
		width: 77%;
	}

	.login_input2 {
		width: 68%;
	}

	.login_input>>>input {
		border: none;
		padding: 0 5%;
	}

	.pass_border {
		border-bottom: 1px solid #f8f8f8;
	}

	.pass_border {
		margin: 0;
	}

	.login_sub {
		width: 90%;
		border-radius: 20px;
		background: #ab1e31;
	}

	.login_sub2 {
		border-radius: 5px;
		margin-top: 30px;
	}
</style>

<template>
	<div class="body">
		<div class="zh_title">
			<a href="javascript:history.go(-1)">
				<div class="zh_title_left"></div>
			</a>
			个人中心
		</div>

		<div class="ge"></div>

		<div class="pc_main">
			<router-link :to="'/payIn?openId='+openId">
				<div class="pc_list">
					<img src="../../../assets/moneyIcon.png" width="7%" />
					缴费入口
					<div class="zh_title_left zh_title_right"></div>
				</div>
			</router-link>

			<router-link :to="'/orderList?openId='+openId">
				<div class="pc_list">
					<img src="../../../assets/orderIcon.png" width="7%" />
					缴费记录
					<div class="zh_title_left zh_title_right"></div>
				</div>
			</router-link>

		</div>

		<div class="gg">
			公告
		</div>

		<div class="gg_content">
			目前缴费系统开放缴费批次为：2022年春季。征缴类目为：教辅材料费。请家长们务必在支付成功之后返回“缴费记录”界面并截图，将截图发给班主任，缴费才算完成。
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				openId: "",
			};
		},
		created() {
			this.openId = this.$route.query.openId;
			this.getOpenId();
		},
		methods: {

			getOpenId() {
				if (this.openId == undefined) {
					// location.href = "https://api.zhyjy.net/authorize?returnUrl=http://192.168.1.2:8080/indexCenter";
					location.href = "https://api.zhyjy.net/authorize?returnUrl=https://web.zhyjy.net/indexCenter";
				}

			},
		}
	}
</script>

<style scoped="scoped">
	.body {
		background: url(../../../assets/pc_bj.jpg) 0 50px no-repeat;
		background-size: 100% 100%;
		float: left;
		width: 100%;
		height: 100vh;
	}

	.zh_title {
		position: relative;
		height: 50px;
		line-height: 50px;
		font-size: .35rem;
		font-weight: bold;
		text-align: center;
		background: #fff;
		color: #333;
	}

	.zh_title_left {
		width: 10px;
		height: 10px;
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
		position: absolute;
		top: 18px;
		left: 13px;
		transform: rotate(-135deg);
	}

	.pc_main {
		float: left;
		width: 90%;
		margin-left: 5%;
		margin-top: 9%;
	}

	.ge {
		margin-top: 10px;
		float: left;
		width: 100%;
	}

	.pc_list {
		padding: 5%;
		border-bottom: 1px solid #f2f2f2;
		float: left;
		width: 80%;
		margin-left: 5%;
		text-align: left;
		position: relative;
		font-size: .3rem;
	}

	.pc_list img {
		vertical-align: text-bottom;
		margin-right: 5%;
	}

	.zh_title_right {
		right: 5%;
		top: 40%;
		border-top: 1px solid #cccccc;
		border-right: 1px solid #cccccc;
		left: unset;
		transform: rotate(45deg);
	}

	.gg {
		float: left;
		padding: 4px 15px;
		background: #f9dde1;
		color: #d81e37;
		text-align: center;
		border-radius: 0 20px 0 20px;
		margin-left: 15%;
		margin-top: 5%;
		font-size: .22rem;
	}

	.gg_content {
		float: left;
		margin-left: 15%;
		margin-top: 3%;
		font-size: .3rem;
		width: 70%;
		line-height: 25px;
		text-align: left;
	}
</style>

<template>
	<div class="pay_body">

		<div class="zh_title">
			<a href="javascript:history.go(-1)">
				<div class="zh_title_left"></div>
			</a>
			电子票据
		</div>

		<div class="login_n_box">

			<div class="login_text">

				<div class="eb_title">钦州市新华书店电子票据</div>

			</div>

			<div>

				<div class="pay_content">
					<span>交款人:</span> {{data.remark==""?'班主任缴费':data.remark}}<br />
					<span>收款单位:</span> 新华书店教辅收费系统
				</div>

				<div class="pay_content">
					<span>缴费金额:</span> <span class="totalMoney">￥{{data.totalAmount}}</span><br />
					<span>开票时间:</span> {{data.orderTime}}
				</div>

				<router-link :to="'/orderDetails?orderNo='+orderNo">
					<div class="pay_content pay_content_eb">
						<div class="pay_content_l">查看账单</div>
						<div class="zh_title_left zh_title_left_eb"></div>
						<div class="pay_content_r">电子账单</div>
					</div>
				</router-link>
			</div>
		</div>

		<div class="eb_tishi">新华书店公众号</div>

		<img class="ewm_img" src="../../../assets/ewm.jpg" width="40%" />

		<div class="eb_tishi">长按二维码，关注公众号查看缴费结果</div>
		<br/>
		<img class="ewm_img" src="../../../assets/zl.png" width="60%" />

	</div>
</template>

<script>
	import {
		url
	} from "../../../assets/js/api.js";
	import qs from 'qs'

	export default {
		components: {

		},
		data() {
			return {
				data: [], //数据集合
				orderNo: "",
				nickName: "",
				deptName: "",
			};
		},
		created() {
			//获取orderNo
			this.orderNo = this.$route.query.orderNo;
			this.nickName = JSON.parse(localStorage.getItem('userInfo'));
			this.getList();
		},
		methods: {

			getList() {

				let orderNo = this.orderNo;

				let params = {
					orderNo: orderNo,
				};

				this.axios
					.post(url + '/queryPayResult', qs.stringify(params), {})
					.then(res => {
						let data = res.data;
						if (data.code == 200) {
							this.data = data.data;
						} else {
							this.$notify.error({
								title: '提示',
								message: data.msg,
								type: 'success'
							});
						}

					})
					.catch(error => console.log(error))
			},

		}
	}
</script>

<style scoped="scoped">
	.ewm_img {
		margin-top: 20px;
	}

	.eb_tishi {
		float: left;
		margin-top: 20px;
		color: #fff;
		font-size: 14px;
		width: 100%;
	}

	.zh_title_left_eb {
		float: right;
		position: relative !important;
		border-top: 2px solid #666 !important;
		border-right: 2px solid #666 !important;
		transform: rotate(45deg) !important;
		left: 0px !important;
		margin-left: 10px;
	}

	.pay_content_eb {
		height: 50px;
		line-height: 50px;
		background: #f7f7f7;
		margin-top: -1px !important;
		border: none !important;
		margin: 0 !important;
		padding: 0 4%;
	}

	.totalMoney {
		color: #a62d25 !important;
	}

	.eb_title {
		float: left;
		color: #a62d25;
		text-align: center;
		text-align: center;
		font-size: .35rem;
		width: 100%;
		padding: 30px 0;
	}

	.zh_title {
		position: relative;
		height: 50px;
		line-height: 50px;
		font-size: .35rem;
		font-weight: bold;
		text-align: center;
		background: #a8281f;
		color: #fff;
		float: left;
		width: 100%;
	}

	.zh_title_left {
		width: 10px;
		height: 10px;
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
		position: absolute;
		top: 18px;
		left: 13px;
		transform: rotate(-135deg);
	}

	.pay_body {
		background: #a8281f;
		float: left;
		height: 100vh;
		width: 100%;
		font-size: 15px;
		overflow-y: auto;
	}

	.login_n_box {
		float: left;
		margin-left: 4%;
		width: 92%;
		background: #fff;
		border-radius: 5px 5px 0 0;
	}

	.login_title {
		background: #ab1e31;
		font-size: 18px;
		color: #fff;
		height: 100px;
		line-height: 50px;
	}

	.login_text {
		text-align: left;
		margin: 0px 4% 30px 4%;
		color: #999;
	}

	.login_text_big {
		font-size: 22px;
		font-weight: bold;
		color: #333;
	}

	.login_ts {
		float: left;
		font-size: 15px;
		color: #333;
		margin-top: 10px;
	}

	.login_ts span {
		color: #999;
		font-size: 15px;
	}

	.pay_class {
		float: left;
		font-size: 16px;
		margin: 0 4%;
		width: 92%;
		padding: 10px 0 15px 0;
		text-align: left;
		border-bottom: 1px solid #ebebeb;
	}

	.pay_j {
		float: right;
		border-top: 1px solid #999;
		border-right: 1px solid #999;
		height: 10px;
		width: 10px;
		transform: rotate(45deg);
		margin-top: 6px;
	}

	.pay_title {
		float: left;
		margin: 0 4%;
		width: 92%;
		height: 50px;
		line-height: 50px;
		color: #999;
		text-align: left;
	}

	.pay_class_type {
		float: left;
		margin: 0 4%;
		width: 92%;
	}

	.pay_class_type_btn {
		float: left;
		width: 47%;
		height: 40px;
		line-height: 40px;
		color: #999;
		background: #f8f8f8;
		border-radius: 3px;
	}

	.pay_class_type_btn_right {
		float: right;
	}

	.pay_class_type_btn_active {
		background: #ffefe5;
		color: #ab1e31;
	}

	.pay_title2 {
		border-bottom: 1px solid #ebebeb;
	}

	.pay_content {
		float: left;
		margin: 0 4%;
		width: 92%;
		line-height: 40px;
		text-align: left;
		border-bottom: 1px solid #ebebeb;
		color: #999;
		font-size: 15px;
	}

	.pay_content span {
		font-size: 15px;
		color: #333;
	}

	.pay_content_r {
		float: right;
		height: 50px;
		line-height: 50px;
		font-size: 15px;
	}

	.pay_content_l {
		float: left;
		height: 60px;
		line-height: 60px;
		color: #333;
		font-size: 15px;
	}

	.pay_content_r_l {
		float: left;
		color: #ab1e31;
	}

	.pay_content_r_r {
		float: right;
		color: #999;
	}

	.pay_content_r_total {
		color: #ab1e31;
	}

	.pay_content_r_total span {
		color: #333;
		margin-left: 10px;
	}

	.beizhu {
		float: left;
		width: 92%;
		margin: 0 4%;
		padding-bottom: 15px;
	}

	.total {
		float: left;
		width: 100%;
		font-size: 25px;
		margin: 15px 0;
	}

	.login_sub {
		float: left;
		margin-left: 4%;
		width: 92%;
		background: #ab1e31;
		border-radius: 5px;
		margin-bottom: 80px;
	}
</style>

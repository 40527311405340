<template>
	<div class="pay_body">

		<div class="login_title">
			<a href="/indexCenter">
				<div class="zh_title_left"></div>
			</a>

			<a class="contact" href="https://work.weixin.qq.com/kfid/kfcece74e1bff61a3cd">联系客服</a>

			缴费
		</div>

		<div class="login_n_box">

			<div class="login_text">
				<span class="login_ts">{{schoolName}}</span>
			</div>

			<div class="pay_class" @click="showPicker">
				{{className}}
				<div class="pay_j"></div>
			</div>

			<div class="pay_title">
				请选择角色
			</div>

			<div class="pay_class_type">
				<div :class="active_css" @click="jiaoSe(1)">班主任</div>
				<div :class="default_css" @click="jiaoSe(0)">家长</div>
			</div>

			<div v-show="isShowJs" @click="show_pay_box" class="col-xs-12 in_padding pc_list"
				style="border-top:1px solid #f8f8f8;">
				<div class="pull-left pupc_list_l">
					缴费人数
				</div>

				<div class="pc_list_r" style="margin-top:0.05rem;"></div>

				<input type="text" class="input_r input_r2" disabled="disabled" v-model="orderNum" placeholder="" />

			</div>

			<div class="pay_title pay_title2">
				缴费内容
			</div>

			<div class="pay_content pay_content2" v-for="(item,index) in data.termList" :key="index">
				{{item.termList}}
				<div class="pay_content_r">

					<div class="pay_content_r_r" style="color:#ab1e31;">
						￥{{item.price}}
					</div>

				</div>
			</div>

			<div class="pay_content">
				总金额
				<div class="pay_content_r">

					<div class="pay_content_r_r pay_content_r_total">
						￥{{data.totalAmount}}<span>元</span>
					</div>

				</div>
			</div>

			<div class="pay_title pay_title2" style="border-bottom:none;">
				备注
			</div>

			<el-form class="form_box" :model="ruleForm" :rules="rules" ref="ruleForm">
				<el-form-item prop="remark">
					<el-input type="textarea" class="beizhu" placeholder="如家长缴费，则必填学生姓名" v-model="ruleForm.remark">
					</el-input>
				</el-form-item>
			</el-form>
		</div>

		<div class="total">
			<span>￥</span>{{data.totalAmount}}
		</div>

		<el-button class="login_sub login_sub2" type="danger" @click="submitForm()">确认</el-button>

		<div>
			<VuePicker class="picker" :defaultIndex="defaultIndex" :data="pickDataClass" :showToolbar="true"
				@cancel="cancel" @confirm="confirm" :visible.sync="pickerVisible" :layer="2" />
		</div>

		<div v-if="schoolShow">
			<div class="bj"></div>
			<div class="pay_box">
				<h2>缴费人数</h2>
				<input type="text" class="input_r" v-model="orderNum" placeholder="请输入缴费人数" />
				<el-button class="login_sub login_sub2 login_sub3" type="danger" @click="payOk()">确认</el-button>
			</div>
		</div>

	</div>
</template>

<script>
	import VuePicker from 'vue-pickers'
	import {
		url
	} from "../../../assets/js/api.js";
	import qs from 'qs'

	export default {
		components: {
			VuePicker
		},
		data() {
			return {
				data: [],
				orderNum: 1,
				isShowJs: true,
				ruleForm: {
					remark: "",
				},
				rules: {
					remark: [{
						required: true,
						message: '请填写备注',
						trigger: 'blur'
					}, ],
				},
				defaultIndex: [1, 2],
				active_css: "pay_class_type_btn pay_class_type_btn_active",
				default_css: "pay_class_type_btn pay_class_type_btn_right",
				tdata: [],
				class_list: "请选择年级/班级",
				pickerVisible: false,
				pickData: [],
				result: '',
				payItems: [], //商品列表
				paySign: "", //支付码
				payRole: 1, //缴费角色，0-家长，1-老师，2-其他
				totalMoney: 0, //总金额
				openId: "",
				i: 0, //计算器
				amount: 0, //前端计算总金额
				schoolId: "",
				schoolName: "",
				schoolType: "",
				classData: [],
				tdataClass: [],
				pickDataClass: [],
				gradeName: "",
				className: "请选择班级年级",
				classId: "",
				schoolShow: false,
			};
		},
		// created() {

		// 	//获取openId
		// 	this.openId = this.$route.query.openId;
		// 	this.getSchools();
		// },

		created() {
			let authCode = this.$route.query.authCode;
			if (authCode == 'LssVx8e8apvM') {
				this.$router.push({
					name: 'scan_index',
					query: {
						sId: 113
					}
				});
			} else {
				//获取openId
				this.openId = this.$route.query.openId;
				this.getSchools();
			}
		},

		filters: {
			orderNumber(res, payRole) {
				if (payRole == 0) {
					return 1;
				} else {
					return res;
				}
			},
		},

		methods: {

			payOk() {
				this.getList();
				this.schoolShow = false;
			},

			show_pay_box() {
				this.schoolShow = true;
			},

			getClass(schoolId) {

				let params = {
					schoolId: schoolId,
				};

				this.axios
					.post(url + '/queryGCList', qs.stringify(params), {})
					.then(res => {
						let data = res.data;
						if (data.code == 200) {
							this.classData = data.data;
							this.tdataClass = [];
							//赋值年级列表
							for (let i = 0; i < this.classData.length; i++) {

								if (this.classData[i].classList.length > 0) {
									for (let j = 0; j < this.classData[i].classList.length; j++) {
										this.classData[i].classList[j].label = this.classData[i].classList[j]
											.className;
										this.classData[i].classList[j].value = this.classData[i].classList[j].classId;
									}
								}

								this.tdataClass.push({
									label: this.classData[i].gradeName,
									value: this.classData[i].gradeId,
									children: this.classData[i].classList,
								});


							}

							console.log(this.tdataClass);
							this.pickDataClass = this.tdataClass;

						} else {
							this.$notify.error({
								title: '提示',
								message: data.msg,
								type: 'success'
							});
						}

					})
					.catch(error => console.log(error))
			},


			//切换年级数据
			pickerChangeData(res) {
				console.log(res);

				this.pickData = [];
				this.pickData.push(res[0]);
				this.pickData.push(res[0].classList);

			},

			jiaoSe(res) {

				if (res == 1) { //班主任
					this.active_css = "pay_class_type_btn pay_class_type_btn_active";
					this.default_css = "pay_class_type_btn pay_class_type_btn_right";
				} else if (res == 0) { //家长
					this.active_css = "pay_class_type_btn";
					this.default_css = "pay_class_type_btn pay_class_type_btn_right pay_class_type_btn_active";
				}

				if (res == 0) {
					this.isShowJs = false;
					this.orderNum = 1;
				} else {
					this.isShowJs = true;
				}

				this.payRole = res;
				this.getList();
			},

			//支付
			submitForm() {
				if (this.data.itemList == null) {
					this.$notify.error({
						title: '提示',
						message: "请选择对应的年级和班级进行缴费",
						type: 'warning'
					});
					return;
				}

				if (this.classId == "" || this.className == "请选择班级年级") {
					this.$notify.error({
						title: '提示',
						message: "请选择班级年级",
						type: 'warning'
					});
					return;
				}

				if (this.payRole == 1) {
					this.rules.remark[0].required = false;
				} else {
					this.rules.remark[0].required = true;
				}

				if (this.orderNum == "") {
					this.$notify.error({
						title: '提示',
						message: "请输入缴费人数",
						type: 'warning'
					});
					return;
				}

				// if (this.remark == "") {
				// 	this.$notify.error({
				// 		title: '提示',
				// 		message: "请填写备注",
				// 		type: 'warning'
				// 	});
				// 	return;
				// }

				this.$refs.ruleForm.validate((valid) => {
					if (valid) {

						let params = {
							clientNo: this.openId,
							payRole: this.payRole,
							remark: this.ruleForm.remark,
							amount: this.amount,
							schoolType: this.schoolType,
							classId: this.classId,
							orderNum: this.orderNum,
						};

						this.axios
							.post(url + '/createOrder', params, {})
							.then(res => {
								let data = res.data;
								if (data.code == 200) {
									console.log(data);
									location.href = data.data;
								} else {
									this.$notify.error({
										title: '提示',
										message: data.msg,
										type: 'success'
									});
								}

							})
							.catch(error => console.log(error))
					}
				})
			},

			getSchools() {

				let authCode = this.$route.query.authCode;

				let params = {
					authCode: authCode,
				};

				this.axios
					.post(url + '/querySchool', qs.stringify(params), {})
					.then(res => {
						let data = res.data;
						if (data.code == 200) {
							this.schoolId = data.data.schoolId;
							this.schoolName = data.data.schoolName;
							this.schoolType = data.data.schoolType;

							this.getClass(this.schoolId);

						} else {
							this.$notify.error({
								title: '提示',
								message: data.msg,
								type: 'success'
							});
						}

					})
					.catch(error => console.log(error))
			},

			showPicker() {
				this.pickerVisible = true;
			},

			cancel() {
				this.result = 'click cancel result: null'
			},
			confirm(res) {
				console.log(res);

				this.classId = res[1].value;
				this.className = res[0].label + " / " + res[1].label;

				this.getList();

			},

			getList() {

				if (this.orderNum < 1 || this.orderNum == "") {
					return;
				}

				if (this.classId == "") {
					this.$notify.error({
						title: '提示',
						message: "请选择班级年级",
						type: 'success'
					});
					return;
				}

				let params = {
					classId: this.classId,
					orderNum: this.orderNum,
				};

				this.axios
					.post(url + '/queryPayCostItem', qs.stringify(params), {})
					.then(res => {
						let data = res.data;
						if (data.code == 200) {
							this.data = data.data;
							this.amount = this.data.totalAmount;
						} else {
							this.$notify.error({
								title: '提示',
								message: data.msg,
								type: 'success'
							});
						}

					})
					.catch(error => console.log(error))
			},

		}
	}
</script>

<style scoped="scoped">
	.form_box>>>.el-form-item__content {
		float: left;
		width: 100%;
	}

	.pay_body {
		background: #f5f5f5;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}

	.login_n_box {
		float: left;
		margin-left: 4%;
		width: 92%;
		background: #fff;
		border-radius: 7px;
		margin-top: -50px;
	}

	.login_title {
		background: #ab1e31;
		font-size: .35rem;
		color: #fff;
		height: 100px;
		line-height: 50px;
	}

	.login_text {
		text-align: left;
		margin: 0px 4% 40px 4%;
		color: #999;
	}

	.login_text_big {
		font-size: 22px;
		font-weight: bold;
		color: #333;
	}

	.login_ts {
		float: left;
		font-size: .3rem;
		color: #999;
		margin-top: 10px;
	}

	.pay_class {
		float: left;
		font-size: .3rem;
		margin: 0 4%;
		width: 92%;
		padding: 15px 0 15px 0;
		text-align: left;
		border-bottom: 1px dashed #ebebeb;
		border-top: 1px dashed #ebebeb;
	}

	.pay_j {
		float: right;
		border-top: 1px solid #999;
		border-right: 1px solid #999;
		height: 10px;
		width: 10px;
		transform: rotate(45deg);
		margin-top: 6px;
	}

	.pay_title {
		float: left;
		margin: 0 4%;
		width: 92%;
		height: 50px;
		line-height: 50px;
		color: #999;
		text-align: left;
		font-size: .3rem;
	}

	.pay_class_type {
		float: left;
		margin: 0 4%;
		width: 92%;
	}

	.pay_class_type div {
		font-size: .3rem;
	}

	.pay_class_type_btn {
		float: left;
		width: 47%;
		height: 40px;
		line-height: 40px;
		color: #999;
		background: #f8f8f8;
		border-radius: 3px;
	}

	.pay_class_type_btn_right {
		float: right;
	}

	.pay_class_type_btn_active {
		background: #ab1e31;
		color: #fff;
	}

	.pay_title2 {
		border-top: 1px dashed #ebebeb;
		border-bottom: 1px dashed #ebebeb;
	}

	.pay_content {
		float: left;
		margin: 0 4%;
		width: 92%;
		height: auto;
		line-height: 40px;
		text-align: left;
		border-bottom: 1px dashed #ebebeb;
	}

	.pay_content2 {
		font-size: .3rem;
	}

	.pay_content2 .pay_content_r {
		font-size: .3rem;
	}

	.pay_content2 .pay_content_r_l {
		font-size: .3rem;
	}

	.pay_content2 .pay_content_r_r {
		font-size: .3rem;
	}

	.pay_content_r {
		float: right;
		width: 35%;
		font-size: .3rem;
	}

	.pay_content_r_l {
		float: left;
		color: #ab1e31;
		font-size: .3rem;
	}

	.pay_content_r_r {
		float: right;
		color: #999;
		font-size: .3rem;
	}

	.pay_content_r_total {
		color: #ab1e31;
	}

	.pay_content_r_total span {
		color: #333;
		margin-left: 10px;
	}

	.beizhu {
		float: left;
		width: 92%;
		margin: 0 4%;
		padding-bottom: 15px;
	}

	.total {
		float: left;
		width: 100%;
		font-size: 25px;
		margin: 15px 0;
	}

	.login_sub {
		float: left;
		margin-left: 4%;
		width: 92%;
		background: #ab1e31;
		border-radius: 5px;
		margin-bottom: 80px;
	}

	.picker>>>li {
		font-size: .35rem;
	}

	.picker>>>.btn {
		font-size: .35rem;
	}

	.login_sub2>>>span {
		font-size: .35rem;
	}

	.pc_list {
		text-align: left;
		padding: 4%;
		background: #fff;
		margin-top: 1px;
		float: left;
		width: 90%
	}

	.pupc_list_l {
		float: left;
		font-size: .3rem;
	}

	.pc_list_r {
		float: right;
		border-right: 1px solid #999;
		border-bottom: 1px solid #999;
		height: 10px;
		width: 10px;
		transform: rotate(-45deg);
		margin-top: 0.05rem !important;
	}

	.input_r {
		float: right;
		text-align: center;
		width: 90%;
		height: 40px;
		line-height: 40px;
		border: 1px solid #ab1e31;
		outline: none;
		margin: 30px 5%;
		font-size: .3rem;
		background: none;
		border-radius: 3px;
	}

	.zh_title_left {
		width: 10px;
		height: 10px;
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
		position: absolute;
		top: 18px;
		left: 13px;
		transform: rotate(-135deg);
	}

	.contact {
		position: absolute;
		top: 0;
		right: 13px;
		color: #FFFFFF;
		font-size: .3rem;
	}

	.bj {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #000;
		opacity: .4;
	}

	.pay_box {
		position: absolute;
		top: 20%;
		left: 4%;
		width: 92%;
		background: #fff;
		border-radius: 5px;
	}

	.pay_box h2 {
		margin-top: 15px;
	}

	.login_sub3 {
		margin-bottom: 30px;
	}

	.input_r2 {
		font-size: .35rem;
		margin-top: -0.06rem !important;
		width: 50%;
		margin: 0;
		height: .5rem;
		line-height: .5rem;
		border: none;
		text-align: right;
		padding-right: 8px;
		color: #000 !important;
	}
</style>

<template>
	<div class="body">
		<div class="zh_title">
			<a href="javascript:history.go(-1)">
				<div class="zh_title_left"></div>
			</a>
			关于我们
		</div>

		<div class="ge"></div>

		<div class="container-fluid about_content">
			{{data.systemSetting.aboutUs}}
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				data: {},
			};
		},

		methods: {

		},
		created() {
			this.data = JSON.parse(localStorage.getItem('userInfo'));
		}
	}
</script>

<style scoped="scoped">
	.name {
		float: right;
	}

	.body {
		background: #f8f8f8;
		float: left;
		width: 100%;
		height: 100vh;
	}

	.zh_title {
		position: relative;
		height: 50px;
		line-height: 50px;
		font-size: .35rem;
		font-weight: bold;
		text-align: center;
		background: #ab1e31;
		color: #fff;
	}

	.zh_title_left {
		width: 10px;
		height: 10px;
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
		position: absolute;
		top: 18px;
		left: 13px;
		transform: rotate(-135deg);
	}

	.pc_list {
		text-align: left;
		padding: 5%;
		background: #fff;
		margin-top: 1px;
		float: left;
		width: 90%
	}

	.pupc_list_l {
		float: left;
		font-size: 14px;
	}

	.pc_list_r {
		float: right;
		border-right: 1px solid #999;
		border-bottom: 1px solid #999;
		height: 10px;
		width: 10px;
		transform: rotate(-45deg);
	}

	.ge {
		margin-top: 10px;
		float: left;
		width: 100%;
	}

	.pcd_tx {
		float: right;
		width: 34px;
		height: 34px;
		border-radius: 27px;
		margin-top: -10px;
	}

	.phone {
		float: right;
	}
	.about_content{margin-left:4%;width: 92%;text-align: left;line-height: 25px;}
</style>

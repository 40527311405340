<template>
	<div class="set_body">

		<div class="zh_title">
			<a href="javascript:history.go(-1)">
				<div class="zh_title_left"></div>
			</a>
			忘记密码
		</div>

		<div class="login_n_box">

			<div class="pass_title">短信验证码已发送，请填写6位数验证码</div>

			<el-form class="form_box" :model="ruleForm" status-icon :rules="rules" ref="ruleForm">

				<el-form-item prop="user" class="pass_border">
					<span class="phone86">+86</span>
					<el-input class="login_input" placeholder="输入手机号码" v-model="user"></el-input>
				</el-form-item>

				<el-form-item prop="pass" class="pass_border">
					<el-input class="login_input login_input2" placeholder="输入验证码" v-model="pass">
					</el-input>
					<span class="phoneBtn">重新发送</span>
				</el-form-item>
				
				<div class="pass_title">设置新密码</div>

				<el-form-item prop="pass" class="pass_border">
					<el-input class="login_input login_input3" placeholder="输入新的密码" show-password v-model="pass">
					</el-input>
				</el-form-item>
				
				<el-form-item prop="pass" class="pass_border">
					<el-input class="login_input login_input3" placeholder="再次输入密码" show-password v-model="pass">
					</el-input>
				</el-form-item>
				
				<div class="pass_title">6-16位密码、数字或字母</div>

				<el-form-item>
					<el-button class="login_sub login_sub2" type="danger" @click="submitForm('ruleForm')">确认</el-button>
				</el-form-item>
				
			</el-form>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				ruleForm: {},
				pass: "",
				user: "",
				rules: {}
			};
		},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		}
	}
</script>

<style scoped="scoped">
	.form_box{float:left;width: 100%;}
	.set_body {
		background: #f8f8f8;
		height: 100vh;
	}

	.zh_title {
		position: relative;
		height: 50px;
		line-height: 50px;
		font-size: .35rem;
		font-weight: bold;
		text-align: center;
		background: #ab1e31;
		color: #fff;
	}

	.zh_title_left {
		width: 10px;
		height: 10px;
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
		position: absolute;
		top: 18px;
		left: 13px;
		transform: rotate(-135deg);
	}

	.login_n_box {
		float: left;
		margin-left: 4%;
		width: 92%;
	}

	.pass_title {
		float: left;
		width: 100%;
		color: #999;
		margin-top: 20px;
		margin-bottom:15px;
		text-align: left;
	}

	.login_input {
		float: left;
		width: 85%;
		border: none;
	}

	.login_input>>>input {
		border: none;
	}

	.pass_border {
		border-bottom: 1px solid #f8f8f8;
	}

	.phone86 {
		float: left;
		width: 15%;
		text-align: center;
		background: #fff;
	}

	.phoneBtn {
		float: right;
		width: 21%;
		color: #09afe5;
		background: #fff;
	}

	.login_input2 {
		width: 79%;
	}
	.pass_border{margin:0;}
	.login_input3{width: 100%;}
	.login_sub {
		width: 90%;
		border-radius: 20px;
		background: #ab1e31;
	}
	.login_sub2{width:100%;border-radius: 5px;}
</style>

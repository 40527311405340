<template>
	<div class="pay_body">

		<div class="login_title">
			<a href="javascript:history.go(-1)">
				<div class="zh_title_left"></div>
			</a>
			缴费账单
		</div>

		<div class="login_n_box">

			<div class="login_text">
				<span class="login_ts">{{schoolName}}</span>
			</div>

			<div class="pay_class" @click="showPicker">
				{{className}}
				<div class="pay_j"></div>
			</div>

			<div class="totalMoney">
				缴费总金额 <span>{{totalMoney}} 元</span>
			</div>

			<div class="treeBox">
				<el-tree :data="treeData" @node-click="handleNodeClick">
					<span class="custom-tree-node" slot-scope="{ node, data }">
						<span>{{ node.label }}</span>
						<span class="treeNode">
							已缴费：{{ data.hasPayNumber }}人
						</span>
						<span class="treeNode">
							应缴费：{{ data.orderNumber }}人
						</span>
					</span>
				</el-tree>
			</div>

		</div>

		<div>
			<VuePicker class="picker" :defaultIndex="defaultIndex" :data="data" :showToolbar="true" @cancel="cancel"
				@confirm="confirm" :visible.sync="pickerVisible" />
		</div>

	</div>
</template>

<script>
	import VuePicker from 'vue-pickers'
	import {
		url
	} from "../../../assets/js/api.js";
	import qs from 'qs'

	export default {
		components: {
			VuePicker
		},
		data() {
			return {
				termId: "", //学期ID
				batchTypeId: "", //批次类型(0：教辅 1：教材 2：暑假作业 3：寒假作业)
				totalMoney: 0,
				treeData: [{
					id: 1,
					label: '一级 1',
					test1: "321",
					test2: "321",
					children: [{
						id: 4,
						label: '二级 1-1',
						children: [{
							id: 9,
							label: '三级 1-1-1'
						}, {
							id: 10,
							label: '三级 1-1-2'
						}]
					}]
				}, {
					id: 2,
					label: '一级 2',
					children: [{
						id: 5,
						label: '二级 2-1'
					}, {
						id: 6,
						label: '二级 2-2'
					}]
				}, {
					id: 3,
					label: '一级 3',
					children: [{
						id: 7,
						label: '二级 3-1'
					}, {
						id: 8,
						label: '二级 3-2'
					}]
				}],

				schoolName: "请选择学期与批次",
				defaultIndex: [0, 0],
				class_list: "请选择年级/班级",
				pickerVisible: false,
				pickData1: [],
				data: [],
				openId: "",
				classData: [],
				tdataClass: [],
				batchType: [{
					value: 0,
					label: "教辅"
				}, {
					value: 1,
					label: "教材"
				}, {
					value: 2,
					label: "暑假作业"
				}, {
					value: 3,
					label: "寒假作业"
				}],
				gradeName: "",
				className: "请选择学期与批次",
				classId: "",
				schoolShow: false,
			};
		},
		created() {
			this.getOrder();
		},
		filters: {
			orderNumber(res, payRole) {
				if (payRole == 0) {
					return 1;
				} else {
					return res;
				}
			},
		},

		methods: {

			getList(termId, batchType) {
				
				let token = JSON.parse(localStorage.getItem('userInfo'));
				
				let params = {
					userToken: token.userToken,
					termId: termId,
					batchType: batchType,
				};

				this.axios
					.post(url + '/queryPayBills', qs.stringify(params), {})
					.then(res => {
						let data = res.data;
						if (data.code == 200) {
							let res=data.data;
							this.schoolName=res.groupName;
							this.totalMoney=res.totalAmount;
							
							for(let i=0;i<res.schoolList.length;i++){
								res.schoolList[i].label=res.schoolList[i].schoolName;
								res.schoolList[i].children=res.schoolList[i].gradeList;
							}
							
							for(let i=0;i<res.schoolList.length;i++){
								for(let j=0;j<res.schoolList[i].children.length;j++){
									res.schoolList[i].children[j].label=res.schoolList[i].children[j].gradeName;
									res.schoolList[i].children[j].children=res.schoolList[i].children[j].classList;
									
									for(let k=0;k<res.schoolList[i].children[j].children.length;k++){
										res.schoolList[i].children[j].children[k].label=res.schoolList[i].children[j].children[k].className;
									}
								}
							}
							
							console.log(res.schoolList);
							this.treeData=res.schoolList;

						} else {
							this.$notify.error({
								title: '提示',
								message: data.msg,
								type: 'success'
							});

							localStorage.removeItem('userInfo');
							location.href = "https://api.zhyjy.net/authorize?returnUrl=http://web.zhyjy.net";
						}

					})
					.catch(error => console.log(error))

			},

			handleNodeClick(e) {
				console.log(e);
				e.batchType=this.batchTypeId;
				e.termId=this.termId;
				
				for(let key in e){
					if(key=="classId"){
						this.$router.push({
							name: 'payOrderDetails',
							query: {
								res: JSON.stringify(e)
							}
						})
					}
				}
			},

			getOrder() {

				let token = JSON.parse(localStorage.getItem('userInfo'));

				let params = {
					userToken: token.userToken,
					// userToken:"79b88e1da3cd4c33b5e7870b91568e82",
				};

				this.axios
					.post(url + '/queryPayTypeList', qs.stringify(params), {})
					.then(res => {
						let data = res.data;
						if (data.code == 200) {

							for (let i = 0; i < data.data.length; i++) {

								this.pickData1.push({
									label: data.data[i].typeName,
									value: data.data[i].typeId,
								});
							}

							this.data = [...[this.pickData1], ...[this.batchType]];
							this.className = "学期：" + this.data[0][0].label + " / " + "批次：" + this.data[1][0].label;

							this.termId = this.data[0][0].value;
							this.batchTypeId = this.data[1][0].value;

							this.getList(this.termId, this.batchTypeId);

							console.log(this.data);

						} else {
							this.$notify.error({
								title: '提示',
								message: data.msg,
								type: 'success'
							});

							localStorage.removeItem('userInfo');
							location.href = "https://api.zhyjy.net/authorize?returnUrl=http://web.zhyjy.net";
						}

					})
					.catch(error => console.log(error))
			},

			//切换年级数据
			pickerChangeData(res) {
				console.log(res);

				this.pickData = [];
				this.pickData.push(res[0]);
				this.pickData.push(res[0].classList);

			},

			showPicker() {
				this.pickerVisible = true;
			},

			cancel() {
				this.result = 'click cancel result: null'
			},
			confirm(res) {
				console.log(res);
				
				this.termId = res[0].value;
				this.batchTypeId = res[1].value;
				
				this.getList(this.termId, this.batchTypeId);
				
				this.className = "学期：" + res[0].label + " / " + "批次：" + res[1].label;

			},

		}
	}
</script>

<style scoped="scoped">
	.totalMoney {
		float: left;
		width: 96%;
		margin-top: 20px;
		text-align: left;
		padding-left: 4%;
	}

	.totalMoney span {
		color: red;
	}

	.form_box>>>.el-form-item__content {
		float: left;
		width: 100%;
	}

	.pay_body {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}

	.login_n_box {
		float: left;
		width: 100%;
		background: #fff;
		border-radius: 7px;
		margin-top: -50px;
	}

	.login_title {
		background: #ab1e31;
		font-size: .35rem;
		color: #fff;
		height: 100px;
		line-height: 50px;
	}

	.login_text {
		text-align: left;
		margin: 0px 4% 40px 4%;
		color: #999;
	}

	.login_text_big {
		font-size: 22px;
		font-weight: bold;
		color: #333;
	}

	.login_ts {
		float: left;
		font-size: .3rem;
		color: #333;
		margin-top: 10px;
	}

	.pay_class {
		float: left;
		font-size: .3rem;
		margin: 0 4%;
		width: 92%;
		padding: 15px 0 15px 0;
		text-align: left;
		border-bottom: 1px dashed #ebebeb;
		border-top: 1px dashed #ebebeb;
	}

	.pay_j {
		float: right;
		border-top: 1px solid #999;
		border-right: 1px solid #999;
		height: 10px;
		width: 10px;
		transform: rotate(45deg);
		margin-top: 6px;
	}

	.pay_title {
		float: left;
		margin: 0 4%;
		width: 92%;
		height: 50px;
		line-height: 50px;
		color: #999;
		text-align: left;
		font-size: .3rem;
	}

	.pay_class_type {
		float: left;
		margin: 0 4%;
		width: 92%;
	}

	.pay_class_type div {
		font-size: .3rem;
	}

	.pay_class_type_btn {
		float: left;
		width: 47%;
		height: 40px;
		line-height: 40px;
		color: #999;
		background: #f8f8f8;
		border-radius: 3px;
	}

	.pay_class_type_btn_right {
		float: right;
	}

	.pay_class_type_btn_active {
		background: #ab1e31;
		color: #fff;
	}

	.pay_title2 {
		border-top: 1px dashed #ebebeb;
		border-bottom: 1px dashed #ebebeb;
	}

	.pay_content {
		float: left;
		margin: 0 4%;
		width: 92%;
		height: auto;
		line-height: 40px;
		text-align: left;
		border-bottom: 1px dashed #ebebeb;
	}

	.pay_content2 {
		font-size: .3rem;
	}

	.pay_content2 .pay_content_r {
		font-size: .3rem;
	}

	.pay_content2 .pay_content_r_l {
		font-size: .3rem;
	}

	.pay_content2 .pay_content_r_r {
		font-size: .3rem;
	}

	.pay_content_r {
		float: right;
		width: 35%;
		font-size: .3rem;
	}

	.pay_content_r_l {
		float: left;
		color: #ab1e31;
		font-size: .3rem;
	}

	.pay_content_r_r {
		float: right;
		color: #999;
		font-size: .3rem;
	}

	.pay_content_r_total {
		color: #ab1e31;
	}

	.pay_content_r_total span {
		color: #333;
		margin-left: 10px;
	}

	.beizhu {
		float: left;
		width: 92%;
		margin: 0 4%;
		padding-bottom: 15px;
	}

	.total {
		float: left;
		width: 100%;
		font-size: 25px;
		margin: 15px 0;
	}

	.login_sub {
		float: left;
		margin-left: 4%;
		width: 92%;
		background: #ab1e31;
		border-radius: 5px;
		margin-bottom: 80px;
	}

	.picker>>>li {
		font-size: .35rem;
	}

	.picker>>>.btn {
		font-size: .35rem;
	}

	.login_sub2>>>span {
		font-size: .35rem;
	}

	.pc_list {
		text-align: left;
		padding: 4%;
		background: #fff;
		margin-top: 1px;
		float: left;
		width: 90%
	}

	.pupc_list_l {
		float: left;
		font-size: .3rem;
	}

	.pc_list_r {
		float: right;
		border-right: 1px solid #999;
		border-bottom: 1px solid #999;
		height: 10px;
		width: 10px;
		transform: rotate(-45deg);
		margin-top: 0.05rem !important;
	}

	.input_r {
		float: right;
		text-align: center;
		width: 90%;
		height: 40px;
		line-height: 40px;
		border: 1px solid #ab1e31;
		outline: none;
		margin: 30px 5%;
		font-size: .3rem;
		background: none;
		border-radius: 3px;
	}

	.zh_title_left {
		width: 10px;
		height: 10px;
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
		position: absolute;
		top: 18px;
		left: 13px;
		transform: rotate(-135deg);
	}

	.contact {
		position: absolute;
		top: 0;
		right: 13px;
		color: #FFFFFF;
		font-size: .3rem;
	}

	.treeBox {
		float: left;
		width: 100%;
		margin-top: 20px;
	}

	.treeNode {
		float: right;
		margin-left: 20px;
	}

	.treeBox>>>span {
		margin-top: 0;
		font-size: .3rem;
	}
	.treeBox >>> div{
		margin-bottom:5px;
	}
	.treeBox>>>.treeNode {
		color: green;
	}
</style>

<template>
	<div class="pay_body">

		<div class="login_title">
			<a href="javascript:history.go(-1)">
				<div class="zh_title_left"></div>
			</a>
			订单详情
		</div>

		<div class="login_n_box">

			<div class="login_text">
				<span class="login_ts">
					<span>订单编号:</span> {{data.orderNo}}
				</span>

				<span class="login_ts">
					<span>订单状态:</span> {{data.orderStatus|orderStatus}}
				</span>

				<span class="login_ts">
					<span>学校名称:</span> {{data.schoolName}}
				</span>

<!-- 				<span class="login_ts">
					<span>地区名称:</span> {{data.payCostItems[0].regionName}}
				</span> -->

				<span class="login_ts" style="margin:10px 0;">
					<span>缴费内容</span>
				</span>

			</div>

			<div v-for="(item,index) in data.payCostItems" :key="index">

				<!-- 				<div class="pay_title pay_title2">
					缴费内容
				</div> -->

				<!-- 				<div class="pay_content">
					<span>学校名称:</span> {{item.schoolName}}
				</div>
				
				<div class="pay_content">
					<span>年级名称:</span> {{item.gradeName}}
				</div> -->

				<!-- 				<div class="pay_content" v-if="item.className!=null">
					<span>班级名称:</span> {{item.className}}
				</div>
				
				<div class="pay_content">
					<span>地区名称:</span> {{item.regionName}}
				</div> -->

				<div class="pay_content">
					{{item.itemName}}
					<span style="float:right;color:#ab1e31;">￥{{item.price}}</span>
				</div>

			</div>

			<div class="pay_content">
				总金额
				<div class="pay_content_r">

					<div class="pay_content_r_r pay_content_r_total">
						￥{{data.totalAmount}}<span>元</span>
					</div>

				</div>
			</div>

		</div>

<!-- 		<div class="total">
			<span>￥</span>{{data.totalAmount}}
		</div> -->

	</div>
</template>

<script>
	import {
		url
	} from "../../../assets/js/api.js";
	import qs from 'qs'

	export default {
		components: {

		},
		data() {
			return {
				data: [], //数据集合
				orderNo: "",
			};
		},
		filters: {
			orderStatus(res) {
				if (res == 0) {
					return "待支付";
				} else if (res == 1) {
					return "缴费成功";
				} else if (res == 2) {
					return "缴费失败";
				} else if (res == 3) {
					return "退款中";
				} else if (res == 4) {
					return "部分退款";
				} else if (res == 5) {
					return "退款失败";
				}
			},
		},
		created() {
			//获取orderNo
			this.orderNo = this.$route.query.orderNo;

			this.getList();
		},
		methods: {

			getList() {

				let orderNo = this.orderNo;

				let params = {
					orderNo: orderNo,
				};

				this.axios
					.post(url + '/queryOrderDetail', qs.stringify(params), {})
					.then(res => {
						let data = res.data;
						if (data.code == 200) {
							this.data = data.data;
						} else {
							this.$notify.error({
								title: '提示',
								message: data.msg,
								type: 'success'
							});
						}

					})
					.catch(error => console.log(error))
			},

		}
	}
</script>

<style scoped="scoped">
	.pay_body {
		background: #f5f5f5;
		float: left;
		height: 100%;
		width: 100%;
	}

	.login_n_box {
		float: left;
		margin-left: 4%;
		width: 92%;
		background: #fff;
		border-radius: 7px;
		margin-top: -50px;
	}

	.login_title {
		background: #ab1e31;
		font-size: .35rem;
		color: #fff;
		height: 100px;
		line-height: 50px;
	}

	.login_text {
		text-align: left;
		margin: 0px 4% 30px 4%;
		color: #999;
	}

	.login_text_big {
		font-size: 22px;
		font-weight: bold;
		color: #333;
	}

	.login_ts {
		float: left;
		width: 100%;
		font-size: .3rem;
		color: #333;
		margin-top: 10px;
	}

	.login_ts span {
		color: #999;
		font-size: .3rem;
	}

	.pay_class {
		float: left;
		font-size: 16px;
		margin: 0 4%;
		width: 92%;
		padding: 10px 0 15px 0;
		text-align: left;
		border-bottom: 1px solid #ebebeb;
	}

	.pay_j {
		float: right;
		border-top: 1px solid #999;
		border-right: 1px solid #999;
		height: 10px;
		width: 10px;
		transform: rotate(45deg);
		margin-top: 6px;
	}

	.pay_title {
		float: left;
		margin: 0 4%;
		width: 92%;
		height: 50px;
		line-height: 50px;
		color: #999;
		text-align: left;
	}

	.pay_class_type {
		float: left;
		margin: 0 4%;
		width: 92%;
	}

	.pay_class_type_btn {
		float: left;
		width: 47%;
		height: 40px;
		line-height: 40px;
		color: #999;
		background: #f8f8f8;
		border-radius: 3px;
	}

	.pay_class_type_btn_right {
		float: right;
	}

	.pay_class_type_btn_active {
		background: #ffefe5;
		color: #ab1e31;
	}

	.pay_title2 {
		color: #333;
		border-bottom: 1px solid #666;
	}

	.pay_content {
		float: left;
		margin: 0 4%;
		width: 92%;
		height: auto;
		line-height: 40px;
		text-align: left;
		border-bottom: 1px dashed #ebebeb;
		border-top: 1px dashed #ebebeb;
		font-size: .3rem;
	}

	.pay_content span {
		color: #999;
	}

	.pay_content_r {
		float: right;
		width: 35%;
	}

	.pay_content_r_l {
		float: left;
		color: #ab1e31;
	}

	.pay_content_r_r {
		float: right;
		color: #999;
	}

	.pay_content_r_total {
		color: #ab1e31;
	}

	.pay_content_r_total span {
		color: #333;
		margin-left: 10px;
	}

	.beizhu {
		float: left;
		width: 92%;
		margin: 0 4%;
		padding-bottom: 15px;
	}

	.total {
		float: left;
		width: 100%;
		font-size: 25px;
		margin: 15px 0;
	}

	.login_sub {
		float: left;
		margin-left: 4%;
		width: 92%;
		background: #ab1e31;
		border-radius: 5px;
		margin-bottom: 80px;
	}

	.zh_title_left {
		width: 10px;
		height: 10px;
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
		position: absolute;
		top: 18px;
		left: 13px;
		transform: rotate(-135deg);
	}
</style>

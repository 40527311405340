<template>
	<div>
		<div class="pc_top">
			<img :src="userInfo.avatar" width="70px" height="70px" :onerror="defaultImg" /><br />
			{{userInfo.nickName}}<br />
			<span>{{userInfo.deptName}}</span>
		</div>

		<div class="zh_bottom_box">
			<router-link to="/payOrder">
				<div class="zh_bottom_li">
					<img src="../../../assets/1_03.jpg"><br>
					缴费账单
				</div>
			</router-link>

			<router-link to="/myInfo">
				<div class="zh_bottom_li">
					<img src="../../../assets/1_05.jpg"><br>
					个人资料
				</div>
			</router-link>
			<!-- 			<div class="zh_bottom_li">
				<img src="../../../assets/1_08.jpg"><br>
				消息提醒
			</div> -->

			<router-link to="/systemSet">
				<div class="zh_bottom_li" style="margin-right:0;">
					<img src="../../../assets/1_13.jpg"><br>
					系统设置
				</div>
			</router-link>

			<div class="zh_bottom_li" @click="loginOut">
				<img src="../../../assets/1_16.png"><br>
				退出登录
			</div>
		</div>

	</div>
</template>

<script>
	import {
		url
	} from "../../../assets/js/api.js";
	import qs from 'qs'
	export default {
		data() {
			return {
				userInfo: {},
			};
		},

		computed: {
			defaultImg() {
				return 'this.src="' + require('@/assets/profile.png') + '"';
			}
		},

		created() {
			this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
		},
		methods: {
			loginOut() {

				let token = JSON.parse(localStorage.getItem('userInfo'));

				let params = {
					userToken: token.userToken,
				};

				this.axios
					.post(url + '/logout', qs.stringify(params), {})
					.then(res => {
						let data = res.data;
						if (data.code == 200) {
							this.$notify({
								title: '提示',
								message: data.msg,
								type: 'success'
							});

							localStorage.removeItem('userInfo');

							location.href ="https://api.zhyjy.net/authorize?returnUrl=http://web.zhyjy.net";
								
						} else {
							this.$notify.error({
								title: '提示',
								message: data.msg,
								type: 'success'
							});
							localStorage.removeItem('userInfo');
							location.href ="https://api.zhyjy.net/authorize?returnUrl=http://web.zhyjy.net";
						}

					})
					.catch(error => console.log(error))
			},
		}
	}
</script>

<style scoped="scoped">
	.pc_top {
		float: left;
		width: 100%;
		text-align: center;
		font-size: 20px;
		padding: 50px 0 30px 0;
		;
		line-height: 25px;
		background: linear-gradient(#f6e7b9, #fff);
	}

	.pc_top span {
		font-size: 16px;
		color: #999;
	}

	.pc_top img {
		border-radius: 50%;
		margin-bottom: 15px;
	}

	.zh_bottom_box {
		float: left;
		margin-left: 3%;
		width: 94%;
	}

	.zh_bottom_li {
		padding-top: 20px;
		float: left;
		width: 31%;
		text-align: center;
		font-size: 15px;
		line-height: 25px;
		height: 12vh;
		vertical-align: bottom;
		background: #fefaf2;
		margin-right: 3.5%;
		margin-top: 3.5%;
		border-radius: 3px;
	}

	.zh_bottom_li:nth-child(3n) {
		margin-right: 0;
	}

	.zh_bottom_li img {
		width: 35%;
	}
</style>

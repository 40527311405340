<template>
	<div class="body">
		<div class="zh_title">
			<a href="javascript:history.go(-1)">
				<!-- <div class="zh_title_left"></div> -->
			</a>
			缴费
		</div>

		<div class="ge"></div>

		<div v-if="schoolType==1" class="col-xs-12 in_padding pc_list" style="border-top:1px solid #f8f8f8;"
			@click="showPickerType">
			<div class="pull-left pupc_list_l">
				学校类型
			</div>

			<div class="pc_list_r" style="margin-top:0.05rem;"></div>
			<input disabled="disabled" type="text" v-model="typeName" class="input_r" placeholder="请选择学校类型" />

		</div>

		<div v-if="typeValue==1" class="col-xs-12 in_padding pc_list" style="border-top:1px solid #f8f8f8;">
			<div class="pull-left pupc_list_l">
				村小校名
			</div>

			<div class="pc_list_r" style="margin-top:0.05rem;"></div>
			<input type="text" v-model="schoolName" class="input_r" placeholder="请填写所属的村小的校名" />

		</div>

		<div class="col-xs-12 in_padding pc_list" style="border-top:1px solid #f8f8f8;" @click="showPickerClass">
			<div class="pull-left pupc_list_l">
				所在班级
			</div>

			<div class="pc_list_r" style="margin-top:0.05rem;"></div>
			<input disabled="disabled" type="text" v-model="className" class="input_r" placeholder="请选择所在班级" />

		</div>

		<div class="col-xs-12 in_padding pc_list" style="border-top:1px solid #f8f8f8;" @click="showPickerJs">
			<div class="pull-left pupc_list_l">
				选择角色
			</div>

			<div class="pc_list_r" style="margin-top:0.05rem;"></div>
			<input disabled="disabled" type="text" class="input_r" v-model="payRoleName" placeholder="请选择角色" />

		</div>

		<div v-show="!isShowJs" class="col-xs-12 in_padding pc_list" style="border-top:1px solid #f8f8f8;">
			<div class="pull-left pupc_list_l">
				学生姓名
			</div>

			<div class="pc_list_r" style="margin-top:0.05rem;"></div>
			<input type="text" class="input_r" v-model="studentName" placeholder="请输入学生姓名" />

		</div>

		<div v-show="isShowJs" class="col-xs-12 in_padding pc_list" style="border-top:1px solid #f8f8f8;">
			<div class="pull-left pupc_list_l">
				缴费人数
			</div>

			<div class="pc_list_r" style="margin-top:0.05rem;"></div>
			<input type="text" class="input_r" v-model="orderNum" placeholder="请输入缴费人数" />

		</div>

		<el-button class="login_sub login_sub2" type="danger" @click="goPay">前往缴费</el-button>

		<div v-if="schoolShow" class="bj"></div>

		<div>
			<VuePicker class="per_list" :defaultIndex="defaultIndexClass" :data="pickDataClass" :showToolbar="true"
				@cancel="cancelClass" @confirm="confirmClass" :visible.sync="pickerVisibleClass" :layer="2" />

			<VuePicker class="per_list" :defaultIndex="defaultIndexJs" :data="pickDataJs" :showToolbar="true"
				@cancel="cancelJs" @confirm="confirmJs" :visible.sync="pickerVisibleJs" :layer="2" />
			<!-- 学校类型 -->
			<VuePicker v-if="schoolType==1" class="per_list" :defaultIndex="defaultIndexType" :data="pickDataType"
				:showToolbar="true" @cancel="cancelType" @confirm="confirmType" :visible.sync="pickerVisibleType"
				:layer="2" />

		</div>

	</div>
</template>

<script>
	import VuePicker from 'vue-pickers'
	import {
		url
	} from "../../../assets/js/api.js";
	import qs from 'qs'
	export default {
		components: {
			VuePicker
		},
		data() {
			return {
				schoolName: "",
				studentName: "",
				schoolType: "",
				openId: "",
				orderNum: "",
				isShowJs: true,
				payRoleName: "",
				payRole: "",
				classId: "",
				className: "",
				classData: [],
				schoolId: "",
				schoolData: {},
				currentIndex: null,
				imgSrc: require('../../../assets/login_gou2.png'),
				imgSrc2: require('../../../assets/login_gou1.png'),
				searchKey: "",
				schoolShow: false,
				schoolList: [],
				typeName: "",
				typeValue: "",

				//地区
				areaId: "",
				area: "",
				defaultIndex: [1, 2],
				pickData: [],
				pickerVisible: false,
				tdata: [],

				//班级
				defaultIndexClass: [1, 2],
				pickDataClass: [],
				pickerVisibleClass: false,
				tdataClass: [],

				//角色
				defaultIndexJs: [1, 2],
				defaultIndexType: [1, 2],
				pickDataJs: [],
				pickDataType: [],
				pickerVisibleJs: false,
				pickerVisibleType: false,
				pickerVisibleSchool: false,
				tdataJs: [],
			};
		},
		created() {
			//回填
			this.openId = this.$route.query.openId;
			this.schoolType = sessionStorage.getItem('sType');
			this.studentName = sessionStorage.getItem('studentName');
			this.schoolName = sessionStorage.getItem('schoolName');
			
			this.orderNum = sessionStorage.getItem('orderNum');

			this.classId = sessionStorage.getItem('classId');
			this.className = sessionStorage.getItem('className');

			this.payRoleName = sessionStorage.getItem('payRoleName');
			this.payRole = sessionStorage.getItem('payRole');

			this.typeName = sessionStorage.getItem('typeName');
			this.typeValue = sessionStorage.getItem('typeValue');

			//角色初始化
			if (this.payRole == 0) {
				this.isShowJs = false;
				this.orderNum = 1;
			} else {
				this.isShowJs = true;
			}
			
			this.getJs();
			this.getType();

		},
		methods: {

			goPay() {

				sessionStorage.setItem('orderNum', this.orderNum);
				sessionStorage.setItem('studentName', this.studentName);
				sessionStorage.setItem('schoolName', this.schoolName);
				
				//处理为null数据
				if(this.studentName=="null"){
					this.studentName="";
				}
				
				if(this.schoolName=="null"){
					this.schoolName="";
				}

				let res = {

					schoolId: this.schoolId,
					classId: this.classId,
					payRole: this.payRole,
					orderNum: this.orderNum,
					openId: this.openId,
					schoolType: this.typeValue,
					studentName: this.studentName,
					schoolName:this.schoolName,
				};

				if (res.schoolType == null && this.schoolType == 1) {

					this.$notify.error({
						title: '提示',
						message: "请选择学校类型",
						type: 'warning'
					});
					return;

				}

				if (res.classId == "" || res.classId == null) {
					this.$notify.error({
						title: '提示',
						message: "请选择所在班级",
						type: 'warning'
					});
					return;
				}

				if (res.payRole === "" || res.payRole == null) {
					this.$notify.error({
						title: '提示',
						message: "请选择角色",
						type: 'warning'
					});
					return;
				}

				if (res.payRole == 1 && res.orderNum == "" || res.payRole == 1 && res.orderNum == null) {
					this.$notify.error({
						title: '提示',
						message: "请输入缴费人数",
						type: 'warning'
					});
					return;
				}

				if (res.payRole == 0 && res.studentName == "" || res.payRole == 0 && res.studentName == null) {
					this.$notify.error({
						title: '提示',
						message: "请输入学生姓名",
						type: 'warning'
					});
					return;
				}
				
				if(res.studentName){
					if (res.studentName.length < 2 && res.payRole == 0 || res.studentName.length > 4 && res.payRole == 0) {
						this.$notify.error({
							title: '提示',
							message: "请输入2-4个字的学生姓名",
							type: 'warning'
						});
						return;
					}
				}
				
				if(res.schoolName==undefined &&this.typeValue==1||res.schoolName==null &&this.typeValue==1||res.schoolName=="" &&this.typeValue==1){
					this.$notify.error({
						title: '提示',
						message: "请输入输入学校名称",
						type: 'warning'
					});
					return;
				}

				this.$router.push({
					name: 'pay2',
					query: {
						res: res
					}
				})

			},

			getJs() {
				this.pickDataJs.push({
					label: "家长",
					value: 0,
				}, {
					label: "班主任",
					value: 1,
				});
			},

			getType() {
				this.pickDataType.push({
					label: "本部",
					value: 0,
				}, {
					label: "村小",
					value: 1,
				});
			},

			getClass() {

				let params = {
					schoolId: sessionStorage.getItem('sId'),
				};

				this.axios
					.post(url + '/queryGCList', qs.stringify(params), {})
					.then(res => {
						let data = res.data;
						if (data.code == 200) {
							this.classData = data.data;
							this.tdataClass = [];
							//赋值年级列表
							for (let i = 0; i < this.classData.length; i++) {

								if (this.classData[i].classList.length > 0) {
									for (let j = 0; j < this.classData[i].classList.length; j++) {
										this.classData[i].classList[j].label = this.classData[i].classList[j]
											.className;
										this.classData[i].classList[j].value = this.classData[i].classList[j].classId;
									}
								}

								this.tdataClass.push({
									label: this.classData[i].gradeName,
									value: this.classData[i].gradeId,
									children: this.classData[i].classList,
								});


							}

							console.log(this.tdataClass);
							this.pickDataClass = this.tdataClass;

						} else {
							this.$notify.error({
								title: '提示',
								message: data.msg,
								type: 'success'
							});
						}

					})
					.catch(error => console.log(error))
			},

			showPickerClass() {

				this.getClass();

				this.pickerVisibleClass = true;
			},

			//学校类型
			showPickerType() {
				this.pickerVisibleType = true;
			},

			showPickerJs() {
				this.pickerVisibleJs = true;
			},

			confirmClass(res) {
				console.log(res);
				this.classId = res[1].value;
				this.className = res[0].label + "/" + res[1].label;

				sessionStorage.setItem('classId', this.classId);
				sessionStorage.setItem('className', this.className);
			},

			confirmJs(res) {
				console.log(res);
				this.payRoleName = res[0].label;
				this.payRole = res[0].value;

				sessionStorage.setItem('payRoleName', this.payRoleName);
				sessionStorage.setItem('payRole', this.payRole);

				if (this.payRole == 0) {
					this.isShowJs = false;
					this.orderNum = 1;
				} else {
					this.isShowJs = true;
					this.orderNum = "";
				}

			},

			confirmType(res) {
				console.log(res);
				this.typeName = res[0].label;
				this.typeValue = res[0].value;
				
				if(this.typeValue==0){
					this.schoolName="";
				}
				
				sessionStorage.setItem('typeName', this.typeName);
				sessionStorage.setItem('typeValue', this.typeValue);

			},

			cancel() {
				this.result = 'click cancel result: null'
			},

			cancelClass() {
				this.result = 'click cancel result: null'
			},

			cancelJs() {
				this.result = 'click cancel result: null'
			},

			cancelType() {
				this.result = 'click cancel result: null'
			},

		}
	}
</script>

<style scoped="scoped">
	.body {
		background: #f8f8f8;
		float: left;
		width: 100%;
		height: 100vh;
	}

	.zh_title {
		position: relative;
		height: 50px;
		line-height: 50px;
		font-size: .35rem;
		font-weight: bold;
		text-align: center;
		background: #ab1e31;
		color: #fff;
	}

	.zh_title_left {
		width: 10px;
		height: 10px;
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
		position: absolute;
		top: 18px;
		left: 13px;
		transform: rotate(-135deg);
	}

	.pc_list {
		text-align: left;
		padding: 4%;
		background: #fff;
		margin-top: 1px;
		float: left;
		width: 90%
	}

	.pupc_list_l {
		float: left;
		font-size: .3rem;
	}

	.pc_list_r {
		float: right;
		border-right: 1px solid #999;
		border-bottom: 1px solid #999;
		height: 10px;
		width: 10px;
		transform: rotate(-45deg);
		margin-top: 0.05rem !important;
	}

	.ge {
		margin-top: 10px;
		float: left;
		width: 100%;
	}

	.input_r {
		float: right;
		text-align: right;
		width: 68%;
		border: none;
		outline: none;
		margin: 0 2%;
		font-size: .3rem;
		background: none;
	}

	.login_sub {
		position: absolute;
		left: 4%;
		bottom: 4%;
		width: 92%;
		background: #ab1e31;
		border-radius: 5px;
	}

	.bj {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #000;
		opacity: .4;
	}

	.selectSchool {
		position: absolute;
		left: 8%;
		top: 9%;
		width: 84%;
		background: #fff;
		height: 80%;
		border-radius: 10px;
	}

	.selectSchoolTitle {
		width: 100%;
		font-size: .35rem;
		text-align: center;
		padding: .2rem 0;
		border-bottom: 1px solid #ddd;
	}

	.selectSchoolSearch {
		background: #f0f0f0;
		border-radius: 1rem;
		padding: .1rem 0;
		margin-top: .3rem;
		width: 92%;
		margin-left: 4%;
	}

	.selectSchoolSearch input {
		border: none;
		background: none;
		font-size: .3rem;
		line-height: 25px;
		outline: none;
		width: 75%;
	}

	.selectSchoolSearch img {
		margin-right: 15px;
		margin-top: .1rem;
		float: left;
		margin: 0.08rem 0.08rem 0.08rem 0.45rem;
	}

	.schoolList {
		text-align: left;
		font-size: .3rem;
		width: 92%;
		margin-left: 4%;
		margin-top: .3rem;
	}

	.schoolList img {
		float: left;
		margin-right: 15px;
	}

	.schoolListBox {
		width: 100%;
		height: 65%;
		overflow-y: auto;
	}

	.per_list>>>span {
		font-size: .3rem;
	}
</style>

<template>
	<div class="body">
		<div class="zh_title">
			<a href="javascript:history.go(-1)">
				<div class="zh_title_left"></div>
			</a>
			系统设置
		</div>

		<div class="ge"></div>

		<div @click="showMess('about')" class="col-xs-12 in_padding pc_list" style="border-top:1px solid #f8f8f8;">
			<div class="pull-left pupc_list_l">
				关于我们
			</div>

			<div class="pc_list_r" style="margin-top:0.05rem;"></div>

		</div>
<!-- 
		<div @click="showMess('help')" class="col-xs-12 in_padding pc_list" style="border-top:1px solid #f8f8f8;">
			<div class="pull-left pupc_list_l">
				使用帮助
			</div>

			<div class="pc_list_r" style="margin-top:0.05rem;"></div>

		</div> -->

		<router-link to="/modifyPassword">
			<div class="col-xs-12 in_padding pc_list" style="border-top:1px solid #f8f8f8;">
				<div class="pull-left pupc_list_l">
					修改密码
				</div>

				<div class="pc_list_r" style="margin-top:0.05rem;"></div>

			</div>
		</router-link>

		<div @click="showMess('concat')" class="col-xs-12 in_padding pc_list" style="border-top:1px solid #f8f8f8;">
			<div class="pull-left pupc_list_l">
				联系我们
			</div>

			<div class="pc_list_r" style="margin-top:0.05rem;"></div>

		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				ruleForm: {},
				pass: "",
				user: "",
				rules: {}
			};
		},
		methods: {
			
			showMess(mes){
				this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
				if(mes=='about'){
					this.$router.push('/about')
				}
				else if(mes=='help'){
					location.href=this.userInfo.systemSetting.useHelp;
				}
				else if(mes=='concat'){
					location.href='tel://'+this.userInfo.systemSetting.contactUs;
				}
			},
			
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		}
	}
</script>

<style scoped="scoped">
	.body {
		background: #f8f8f8;
		float: left;
		width: 100%;
		height: 100vh;
	}

	.zh_title {
		position: relative;
		height: 50px;
		line-height: 50px;
		font-size: .35rem;
		font-weight: bold;
		text-align: center;
		background: #ab1e31;
		color: #fff;
	}

	.zh_title_left {
		width: 10px;
		height: 10px;
		border-top: 2px solid #fff;
		border-right: 2px solid #fff;
		position: absolute;
		top: 18px;
		left: 13px;
		transform: rotate(-135deg);
	}

	.pc_list {
		text-align: left;
		padding: 5%;
		background: #fff;
		margin-top: 1px;
		float: left;
		width: 90%
	}

	.pupc_list_l {
		float: left;
		font-size: .35rem;
	}

	.pc_list_r {
		float: right;
		border-right: 1px solid #999;
		border-bottom: 1px solid #999;
		height: 10px;
		width: 10px;
		transform: rotate(-45deg);
	}

	.ge {
		margin-top: 10px;
		float: left;
		width: 100%;
	}
</style>
